<script>
import CustomAccordion from "../custom-accordion/CustomAccordion.vue";
import resolve_img_url from "../../utils/image/image-utils";
import { isMobile } from "../../utils/mobile/mobile";

const AccordionList = {
  props: [
    "incrementalTitle",
    "titleKeyName",
    "incrementalIndex",
    "items",
    "itemsFields",
    "emptyText",
    "iconName",
    "itemsFieldsFunction",
    "getCustomTable",
    "onEdit",
    "onDelete",
    "msgToggleEdit",
    "msgToggleDelete",
  ],
  data() {
    return {
      currentIconEdit: resolve_img_url("pencil-outline.svg"),
      hoverIconEdit: resolve_img_url("pencil-outline-houver.svg"),
      currentIconDelete: resolve_img_url("trash-can-outline.svg"),
      hoverIconDelete: resolve_img_url("trash-can-outline-houver.svg"),
    };
  },
  components: { CustomAccordion },
  render() {
    const items = this.$props.items;
    const iconArray = this.$props.iconName;
    const itemsFields = this.$props.itemsFields;
    const itemsFieldsFunction = this.$props.itemsFieldsFunction;
    const incrementalTitle = this.$props.incrementalTitle || "";
    const incrementalIndex = !!incrementalTitle;
    const titleKeyName = this.$props.titleKeyName;
    const emptyText = this.$props.emptyText;
    const getCustomTable = this.$props.getCustomTable;

    const buildIcons = function(icons) {
      if (icons.length) {
        return icons.map((icon) => {
          if (!icon.image) return;
          return (
            <img
              class="ml-3"
              src={resolve_img_url(icon.image)}
              data-toggle="tooltip"
              data-placement="top"
              title={icon.tooltip}
            />
          );
        });
      }
    };
    const getHeaderButton = (item) => {
      const onClickHandlerEdit = (e) => {
        e?.stopPropagation();
        this.$props.onEdit(item);
      };

      const onClickHandlerDelete = (e) => {
        e?.stopPropagation();
        this.$props.onDelete(item);
      };
      return (
        <template slot="header-button">
          {this.$props.onEdit ? (
            <div class="border-edit">
              <div
                class="custom-icon-edit"
                onclick={onClickHandlerEdit}
                data-toggle="tooltip"
                data-placement="top"
                title={this.$props.msgToggleEdit}
              />
            </div>
          ) : (
            ""
          )}
          {this.$props.onDelete ? (
            <div class="border-delete">
              <div
                onclick={onClickHandlerDelete}
                class="custom-icon-delete"
                v-b-modal="delete-attention-modal"
                data-toggle="tooltip"
                data-placement="top"
                title={this.$props.msgToggleDelete}
              />
            </div>
          ) : (
            ""
          )}
        </template>
      );
    };

    const getHeader = function(item, index) {
      var titulo = "";
      var icons = [];
      let element = "";
      let titleKeyNameArray = titleKeyName.split(";");

      for (let index = 0; index < titleKeyNameArray.length; index++) {
        element = titleKeyNameArray[index];

        if (index > 0) {
          titulo += " - ";
        }

        titulo += item[element];

        if (iconArray) {
          for (let contador = 0; contador < iconArray.length; contador++) {
            icons[contador] = {
              image: item[iconArray[contador].icon],
              tooltip: iconArray[contador].tooltip,
            };
          }
        }
      }

      return incrementalIndex ? (
        <template slot="header" class="d-flex align-items-center">
          <h6 class="accordion-header upperCase">
            {`${incrementalTitle}  ${index + 1}`}
            {buildIcons(icons)}
          </h6>
        </template>
      ) : (
        <template slot="header" class="d-flex align-items-center">
          <h6 class="accordion-header">
            {`${titulo}`}
            {buildIcons(icons)}
          </h6>
        </template>
      );
    };
    return (
      <div class="accordion-container">
        {items &&
          items.map((item, index) => (
            <custom-accordion
              visibleIfOnlyOne={items.length == 1}
              class="mb-3"
              collapseId={`collaspse1 ${index}`}
            >
              {getHeader(item, index)}
              {getHeaderButton(item)}
              <template slot="content">
                <hr class="accordion-hr" />
                {getCustomTable && getCustomTable(item)}
                {!getCustomTable && (
                  <b-table
                    class={
                      isMobile()
                        ? "custom-table spaced-text custom-table-mobile"
                        : "custom-accordion-table custom-table spaced-text"
                    }
                    stacked
                    fields={itemsFieldsFunction?.(item) || itemsFields}
                    items={[item]}
                  ></b-table>
                )}
              </template>
            </custom-accordion>
          ))}
        {(!items || items.length <= 0) && (
          <b-card border-variant="light" class="accordion-empty">
            <h5>
              {emptyText ? this.emptyText : "Nenhuma informação para exibir!"}
            </h5>
            <p />
          </b-card>
        )}
      </div>
    );
  },
};

export default AccordionList;
</script>

<style lang="scss" scoped>
.accordion-header {
  color: var(--primary);
  padding-top: 5px;
}

.accordion-container td::before {
  text-align: left !important;
}
.custom-table {
  padding: 0rem;
}
.custom-table-mobile td {
  padding: 0rem;
  border-color: #b40000;
}
.accordion-container td:first-child {
  border-color: #eff2f5;
}

.accordion-empty {
  text-align: center;
  border-radius: 10px;
}

.accordion-hr {
  padding: none;
  margin: 0rem;
  border-color: #eff2f5;
}

.custom-icon-edit {
  margin-right: 12px;
  padding: 4px;
  background-image: url("../../assets/icons/pencil-outline.svg");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-position: center;
  cursor: pointer;
  &:hover {
    background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../assets/icons/pencil-outline-houver.svg");
    background-position: center;
  }
}

.border-delete .custom-icon-delete {
  margin-right: 12px;
  padding: 4px;
  background-image: url("../../assets/icons/trash-can-outline.svg");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-position: center;
  &:hover {
    background: rgba(213, 36, 83, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../assets/icons/trash-can-outline-houver.svg");
    background-position: center;
  }
}
</style>
